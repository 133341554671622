import { Link, graphql, navigate } from "gatsby"
import React, { useEffect } from "react"

export default function BlogIndexRedirect({
  data: {
    allMdx: { edges },
  },
}) {
  const { slug: url, frontmatter } = edges
    .map(e => e.node)
    .filter(
      node => node.fields.slug.startsWith("/blog/") && !node.frontmatter.draft
    )
    .sort(
      (a, b) => new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
    )[0]

  useEffect(() => {
    navigate(`/blog/${url}`, { replace: true })
  }, [url])
  return <Link to={`/blog/${url}`}>Last post: {frontmatter.title}</Link>
}

export const pageQuery = graphql`
  query IndexQuery {
    allMdx {
      edges {
        node {
          slug
          frontmatter {
            date
            title
            draft
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
